<script setup lang="ts">
// icons
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';

import dayjs from '@/utils/day';

// constants
import translationFixtures from '@/locales/fixtures';

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiCampsiteSectionFaqProps {
  campsite: any;
  hasAvailability?: boolean;
  ratingTranslations: any;
}
const props = withDefaults(defineProps<CiCampsiteSectionFaqProps>(), {
  hasAvailability: false,
});

const emit = defineEmits<{
  'scroll-to-ref': [value: string];
}>();

/********************
 * COMPOSITIONS      *
 ********************/
const jsonLdStore = useJsonLdStore();
const localePath = useLocalePath();
const route = useRoute();
const runtimeConfig = useRuntimeConfig();
const { $gettext, interpolate, $pgettext } = useGettext();
const { delegateLinksToRouter } = useDelegateToRouter();
const { locale } = useI18n();
const { getLocalizedPeriod, i18nCampingCardInfoText2, i18nCampingCardInfoAdditionalInfo } = useCampingCard({
  campsite: () => props.campsite,
  campsiteSlug: props.campsite?.slug,
});

/********************
 * REFS & VARS       *
 ********************/
const baseUrl = runtimeConfig.public.baseUrl || 'https://www.camping.info';

const faqs = computed((): { question: string; answer: any }[] => {
  if (!props.campsite) {
    return [];
  }

  return [
    getDistanceToWaterQuestion(),
    getDogsQuestion(),
    getWhenOpenQuestion(),
    getFamilyFriendlyQuestion(),
    getRatingsQuestion(),
    getShoppingQuestion(),
    getNextCityQuestion(),
    getCateringQuestion(),
    getWifiQuestion(),
    getAccessibilityQuestion(),
    getPitchesQuestion(),
    getSizeQuestion(),
    getCostQuestion(),
    getCCAQuestion(),
  ].filter(item => item);
});

const cpdpLinkUrl = computed(() => {
  const cpdpLinkTarget = localePath({
    name: RBN_CAMPSITE_DETAIL,
    params: route.params,
    query: route.query,
  });
  return `${baseUrl}${cpdpLinkTarget}`;
});

const sendRequestLink = computed(() => {
  if (!props.campsite.meta?.inquiries_allowed) {
    return '';
  }
  const sendRequestLinkTarget = localePath({
    name: 'campsite-identifier-inquire',
    params: route.params,
    query: route.query,
  });
  const sendRequestLinkText = $gettext('Send request now');
  return `<a href="${baseUrl}${sendRequestLinkTarget}">${sendRequestLinkText}</a>`;
});

const reviewsLink = computed(() => {
  const reviewsLinkTarget = localePath({
    name: 'campsite-identifier-reviews',
    params: route.params,
    query: route.query,
  });
  const reviewsLinkText = $gettext('Read reviews in detail now');
  return `<a href="${baseUrl}${reviewsLinkTarget}">${reviewsLinkText}</a>`;
});

const rateCampsiteLink = computed(() => {
  const rateLinkTarget = localePath({
    name: 'campsite-identifier-rate',
    params: route.params,
    query: route.query,
  });

  const rateLinkText = interpolate($gettext('Rate %{campsite} now'), { campsite: props.campsite.meta.name }, true);

  return `<a href="${baseUrl}${rateLinkTarget}">${rateLinkText}</a>`;
});

/********************
 * INITIALIZATION    *
 ********************/
// faqs will only be added on SSR
if (import.meta.server && props.campsite?.meta) {
  const faqItems: any[] = [];
  faqs.value.forEach((item) => {
    faqItems.push({
      '@type': 'Question',
      'name': item.question,
      'acceptedAnswer': {
        '@type': 'Answer',
        'text': item.answer,
      },
    });
  });
  jsonLdStore.addFaqs({ items: faqItems, faqId: `${baseUrl}${route.path}#faq` });
}

onBeforeMount(() => {
  jsonLdStore.removeFaqs();
});

/********************
 * FUNCTIONS         *
 ********************/
function getRatingTranslation(key) {
  for (let i = 0; i < props.ratingTranslations.length; i++) {
    if (key === props.ratingTranslations[i].key) {
      return props.ratingTranslations[i].translation;
    }
  }
  return null;
}

function onAnswerClicked($event) {
  const href = $event?.target?.href;

  if (href) {
    try {
      const url = new URL(href);
      if (url.hash === '#availabilities') {
        $event.preventDefault();
        emit('scroll-to-ref', 'availabilities');
        return false;
      } else if (url.hash === '#location') {
        $event.preventDefault();
        emit('scroll-to-ref', 'location');
        return false;
      } else if (url.hash === '#properties') {
        $event.preventDefault();
        emit('scroll-to-ref', 'properties');
        return false;
      } else if (url.hash === '#rating') {
        $event.preventDefault();
        emit('scroll-to-ref', 'rating');
        return false;
      } else if (url.hash === '#about') {
        $event.preventDefault();
        emit('scroll-to-ref', 'about');
        return false;
      } else if (url.hash === '#top') {
        $event.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return false;
      }
    } catch (_e) { /* empty */ }
  }
  return delegateLinksToRouter($event);
}

function getDistanceAndUnit(prop) {
  if (prop) {
    return { distance: getRoundedDistanceOrNull(prop.value), unit: prop.unit || 'km' };
  }
  return { distance: null, unit: 'km' };
}

// methods: {
function getSearchForOthersLink(text, params) {
  const query = {};
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  params && params.forEach((param) => {
    if (typeof param === 'object') {
      query[param.name] = param.value;
    } else {
      query[param] = true;
    }
  });
  const searchForOthersLinkTarget = localePath({
    name: RBN_FEDERAL_STATE_SEARCH,
    params: {
      countrySlug: props.campsite.meta.country.slugs[locale.value],
      federalStateSlug: props.campsite.meta.federal_state.slugs[locale.value],
    },
    query,
  });
  const searchForOthersLinkText = interpolate(text, { federal_state: props.campsite.meta.federal_state.name }, true);
  return `<a href="${baseUrl}${searchForOthersLinkTarget}">${searchForOthersLinkText}</a>`;
}

function getSearchForOthersInRegionLink(text, region, params) {
  const query = {};
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  params && params.forEach((param) => {
    query[param] = true;
  });
  let slug = region.slugs;
  if (!slug) {
    slug = null;
  } else {
    slug = slug[locale.value];
  }
  const searchForOthersLinkTarget = localePath({
    name: RBN_REGION_SEARCH,
    params: {
      regionSlug: slug,
    },
    query,
  });
  const searchForOthersLinkText = interpolate(text, { region: region.name }, true);
  return `<a href="${baseUrl}${searchForOthersLinkTarget}">${searchForOthersLinkText}</a>`;
}

function getFormattedOpeningDate(dt) {
  if (!dt) {
    return null;
  }
  const formats = {
    default: 'D. MMMM',
    en: 'MMMM D',
    it: 'D MMMM',
  };
  const format = formats[locale.value] || formats.default;
  return dayjs(dt).format(format);
}

function getRoundedDistanceOrNull(distance) {
  if (distance === null || distance === undefined) {
    return null;
  }
  return Math.round(distance * 10.0) / 10.0;
}

function getValueOrNull(val) {
  if (val === false) {
    return false;
  }
  return val || null;
}

function getWifiQuestion() {
  const question = $gettext('Is there wifi or internet at the campsite %{campsite}?');

  const searchForOthersLink = getSearchForOthersLink(
    $gettext('Search for other campsites in %{federal_state} that offer Wifi'),
    ['wifi'],
  );

  let answer = $gettext(
    'Unfortunately, the campsite %{campsite} has not yet provided any information '
    + 'about Wifi, but you can request the info directly from the campsite. %{sendRequestLink}<br /><br />'
    + '%{searchForOthersLink}',
  );

  const wifi = props.campsite.equipment?.raw_wifi || { wifi: 0, costs: 0 };
  if (wifi.wifi === 1) {
    answer = $gettext('The campsite %{campsite} unfortunately does not offer wifi.');
  } else if (wifi.wifi === 2) {
    answer = $gettext('The campsite %{campsite} offers wifi in part.');
  } else if (wifi.wifi === 3) {
    answer = $gettext('The campsite %{campsite} offers wifi.');
  }

  const { distance: terminalDistance, unit: terminalDistanceUnit } = getDistanceAndUnit(
    props.campsite.equipment?.internet_terminal,
  );

  if (wifi.wifi > 1) {
    if (wifi.costs === 1) {
      answer += ' ' + $gettext('This is free.');
    } else if (wifi.costs === 2) {
      answer += ' ' + $gettext('This is partially free.');
    } else if (wifi.costs === 3) {
      answer += ' ' + $gettext('This is chargeable.');
    }

    if (terminalDistance !== null && terminalDistance >= 0) {
      if (terminalDistance === 0) {
        answer += ' ' + $gettext('In addition, there is an Internet terminal on site.');
      } else {
        answer
          += ' '
            + $gettext('In addition, there is an Internet terminal %{terminalDistance} %{terminalDistanceUnit} away.');
      }
    }
  }

  return {
    question: interpolate(question, { campsite: props.campsite.meta.name }, true),
    answer: interpolate(
      answer,
      {
        searchForOthersLink,
        terminalDistance,
        terminalDistanceUnit,
        sendRequestLink: sendRequestLink.value,
        campsite: props.campsite.meta.name,
        federal_state: props.campsite.meta.federal_state.name,
      },
      true,
    ),
  };
}

function getDogsQuestion() {
  const question = $gettext('Are dogs allowed at the campsite %{campsite}?');

  const searchForOthersLink = getSearchForOthersLink(
    $gettext('Search for other campsites in %{federal_state} that allow dogs'),
    ['dogs_allowed_season', 'dogs_allowed_off_season'],
  );

  let answer = $gettext(
    'The campsite %{campsite} has unfortunately not yet provided any information on '
    + 'whether dogs are allowed on the campsite, but you can request the info directly from '
    + 'the campsite. %{sendRequestLink}<br /><br />'
    + '%{searchForOthersLink}',
  );

  const dogsMainseason = getValueOrNull(props.campsite.general?.dogs_allowed_season);
  const dogsOffseason = getValueOrNull(props.campsite.general?.dogs_allowed_off_season);
  const dogsInRental = getValueOrNull(props.campsite.general?.dogs_allowed_in_rented_accomodation);

  const dogsRating = getValueOrNull(props.campsite.rating_guest?.rating_avg_guests_with_dogs_friendly);

  let isPositive = false;

  if (dogsMainseason !== null || dogsOffseason !== null) {
    if (dogsMainseason === true && dogsOffseason === true) {
      isPositive = true;
      answer = $gettext('Dogs are allowed in high season and low season.');
    } else if (dogsMainseason === false && dogsOffseason === false) {
      answer = $gettext('Dogs are not allowed in high season and low season.<br /><br />%{searchForOthersLink}');
    } else if (dogsMainseason === true) {
      isPositive = true;
      answer = $gettext('Dogs are allowed in high season, but not in low season.');
    } else if (dogsOffseason === true) {
      isPositive = true;
      answer = $gettext('Dogs are allowed in low season, but not in high season.');
    }
    if (isPositive) {
      if (dogsInRental === true) {
        answer += ' ' + $gettext('Dogs are also allowed in rental accommodations.');
      } else if (dogsInRental === false) {
        answer += ' ' + $gettext('Dogs are not allowed in rental accommodations.');
      }
      if (dogsRating !== null) {
        answer += ' ';
        answer += $gettext(
          'Guests rate %{campsite} as "suitable for guests with dogs" with %{dogsRating} out of 5. %{reviewsLink}',
        );
      }
    }
  }

  return {
    question: interpolate(question, { campsite: props.campsite.meta.name }, true),
    answer: interpolate(
      answer,
      {
        searchForOthersLink,
        dogsRating: Math.round(dogsRating * 10.0) / 10.0,
        reviewsLink: reviewsLink.value,
        sendRequestLink: sendRequestLink.value,
        campsite: props.campsite.meta.name,
        federal_state: props.campsite.meta.federal_state.name,
      },
      true,
    ),
  };
}

function getWhenOpenQuestion() {
  const question = $gettext('When is %{campsite} open?');

  const searchForOthersLink = getSearchForOthersLink(
    $gettext('Search for other campsites in %{federal_state} with availabilities'),
    [],
  );

  let answer = $gettext(
    'Unfortunately, the campsite %{campsite} has not yet provided any information about its '
    + 'opening hours, but you can request the info directly from the campsite. %{sendRequestLink}<br /><br /> '
    + '%{searchForOthersLink}',
  );

  const openingDates = props.campsite.meta?.opening_dates || [];
  let openingRangesText = '';
  let idx = 0;
  if (openingDates && openingDates.length) {
    openingDates.forEach((openingDate) => {
      const startDate = getFormattedOpeningDate(openingDate.start);
      const endDate = getFormattedOpeningDate(openingDate.end);
      let prefix = ', ';
      if (idx === 0) {
        prefix = '';
      } else if (idx === openingDates.length - 1) {
        prefix = ' ' + $gettext('and') + ' ';
      }
      openingRangesText += prefix + interpolate($gettext('from %{startDate} until %{endDate}'), { startDate, endDate });
      idx++;
    });
    answer = $gettext('%{campsite} is open %{openingRangesText}.');
    if (props.hasAvailability) {
      answer += ' ' + `<a href="${cpdpLinkUrl.value}#availabilities">${$gettext('Check availabilities now')}</a>`;
    } else {
      answer += ' ' + `<a href="${cpdpLinkUrl.value}#top">${$gettext('Show campsite details')}</a>`;
    }
  }

  return {
    question: interpolate(question, { campsite: props.campsite.meta.name }, true),
    answer: interpolate(
      answer,
      {
        openingRangesText,
        searchForOthersLink,
        sendRequestLink: sendRequestLink.value,
        campsite: props.campsite.meta.name,
        federal_state: props.campsite.meta.federal_state.name,
      },
      true,
    ),
  };
}

function getShoppingQuestion() {
  const question = $gettext('Are there shopping facilities near %{campsite}?');
  let answer = $gettext(
    'Sorry, the campsite %{campsite} has not yet provided any details about shopping '
    + 'facilities, but you can request the info directly from the campsite. %{sendRequestLink}<br /><br /> '
    + '%{searchForOthersLink}',
  );

  const searchForOthersLink = getSearchForOthersLink(
    $gettext('Search for other campsites in %{federal_state} with shopping facilities'),
    ['food_supply'],
  );

  const foodsupply = props.campsite.care?.food_supply;
  let foodsupplyValue = null;
  let foodsupplyUnit = 'km';
  if (foodsupply?.value !== undefined) {
    foodsupplyValue = foodsupply.value;
    foodsupplyUnit = foodsupply.unit;
  }
  const foodsupplyBegin = getFormattedOpeningDate(props.campsite.meta?.opening_hours_foodsupply_begin);
  const foodsupplyEnd = getFormattedOpeningDate(props.campsite.meta?.opening_hours_foodsupply_end);
  const bread = props.campsite.descriptive?.raw_bread_at_campsite || 0;
  const distanceTown = getRoundedDistanceOrNull(props.campsite.distances?.distance_town);
  const distanceCity = getRoundedDistanceOrNull(props.campsite.distances?.distance_city);

  let showLocationLink = false;

  if (foodsupplyValue !== null) {
    showLocationLink = true;
    if (foodsupplyValue === 0) {
      answer = $gettext('There is a grocery store directly on the campsite.');
    } else {
      answer = $gettext('There is a grocery store %{foodsupplyValue} %{foodsupplyUnit} away.');
    }
    if (foodsupplyBegin !== null && foodsupplyEnd !== null) {
      answer += ' ' + $gettext('This is open from %{foodsupplyBegin} to %{foodsupplyEnd}.');
    }

    if (bread > 1) {
      answer += ' ';
      if (bread === 2) {
        answer += $gettext(
          'In addition, during the high season, %{campsite} offers a bread service directly at the site.',
        );
      } else {
        answer += $gettext('In addition, %{campsite} offers a bread service directly at the site.');
      }
    }

    if (distanceTown !== null) {
      showLocationLink = true;
      answer += ' ' + $gettext('The nearest town is %{distanceTown} km away');
      if (distanceCity !== null) {
        answer += ', ' + $gettext('the nearest city is %{distanceCity} km away.');
      } else {
        answer += '.';
      }
    } else if (distanceCity !== null) {
      showLocationLink = true;
      answer += ' ' + $gettext('The nearest city is %{distanceCity} km away.');
    }
  }

  if (showLocationLink) {
    answer += ' ' + `<a href="${cpdpLinkUrl.value}#location">${$gettext('Learn more about the location')}</a>`;
  }

  return {
    question: interpolate(question, { campsite: props.campsite.meta.name }, true),
    answer: interpolate(
      answer,
      {
        foodsupplyBegin,
        foodsupplyEnd,
        foodsupplyValue: Math.round(foodsupplyValue * 10.0) / 10.0,
        foodsupplyUnit,
        searchForOthersLink,
        distanceTown,
        distanceCity,
        sendRequestLink: sendRequestLink.value,
        campsite: props.campsite.meta.name,
        federal_state: props.campsite.meta.federal_state.name,
      },
      true,
    ),
  };
}

function getNextCityQuestion() {
  const searchForOthersLink = getSearchForOthersLink($gettext('Search for other campsites in %{federal_state}'), []);
  const question = $gettext('How far is the nearest town or city from %{campsite}?');
  let answer = $gettext(
    'Unfortunately, the campsite %{campsite} has not yet provided any distance information, '
    + 'but you can also request the info directly from the campsite. %{sendRequestLink}<br /><br /> '
    + '%{searchForOthersLink}',
  );
  const distanceTown = getRoundedDistanceOrNull(props.campsite.distances?.distance_town);
  const distanceCity = getRoundedDistanceOrNull(props.campsite.distances?.distance_city);
  const distanceStation = getRoundedDistanceOrNull(props.campsite.distances?.distance_station);
  const distanceHighwayExit = getRoundedDistanceOrNull(props.campsite.distances?.distance_highway_exit);

  if (distanceTown !== null || distanceCity !== null) {
    if (distanceTown !== null) {
      if (distanceTown === 0) {
        answer = $gettext('The campsite is located in the town');
      } else {
        answer = $gettext('The nearest town is %{distanceTown} km away');
      }
      if (distanceCity !== null) {
        answer += ', ' + $gettext('the nearest city is %{distanceCity} km away.');
      } else {
        answer += '.';
      }
    } else if (distanceCity !== null) {
      if (distanceCity === 0) {
        answer = $gettext('The campsite is located in the city.');
      } else {
        answer = $gettext('The nearest city is %{distanceCity} km away.');
      }
    }
    if (distanceStation !== null) {
      if (distanceStation === 0) {
        answer += ' ' + $gettext('There is a bus or train stop directly on site.');
      } else {
        answer += ' ' + $gettext('You can reach a bus or train stop in %{distanceStation} km.');
      }
    }
    if (distanceHighwayExit !== null) {
      if (distanceHighwayExit === 0) {
        answer += ' ' + $gettext('There is a highway exit directly on site.');
      } else {
        answer += ' ' + $gettext('The nearest highway exit is %{distanceHighwayExit} km away.');
      }
    }
    answer += ' ' + `<a href="${cpdpLinkUrl.value}#location">${$gettext('Learn more about the location')}</a>`;
  }

  return {
    question: interpolate(question, { campsite: props.campsite.meta.name }, true),
    answer: interpolate(
      answer,
      {
        searchForOthersLink,
        distanceTown,
        distanceCity,
        distanceStation,
        distanceHighwayExit,
        sendRequestLink: sendRequestLink.value,
        campsite: props.campsite.meta.name,
        federal_state: props.campsite.meta.federal_state.name,
      },
      true,
    ),
  };
}

function getDistanceToWaterQuestion() {
  const distanceSea = getRoundedDistanceOrNull(props.campsite.distances?.distance_sea);
  const distanceLake = getRoundedDistanceOrNull(props.campsite.distances?.distance_lake);
  const distanceRiver = getRoundedDistanceOrNull(props.campsite.distances?.distance_river);

  const { distance: sandBeachValue, unit: sandBeachUnit } = getDistanceAndUnit(props.campsite.leisure?.sandbeach);

  const { distance: nudistBeachValue, unit: nudistBeachUnit } = getDistanceAndUnit(
    props.campsite.leisure?.nudist_beach,
  );

  if (
    distanceSea === null
    && distanceLake === null
    && distanceRiver === null
    && (sandBeachValue === null || sandBeachValue < 0)
    && (nudistBeachValue === null || nudistBeachValue < 0)
  ) {
    return null;
  }

  let answer;
  let question;
  let isAtWater = false;

  if (sandBeachValue === 0) {
    question = $gettext('How far from the beach is %{campsite}?');
    answer = $gettext('%{campsite} is located directly at the beach.');
    isAtWater = true;
  } else if (distanceSea === 0) {
    question = $gettext('How far from the sea is %{campsite}?');
    answer = $gettext('%{campsite} is located directly at the sea.');
    isAtWater = true;
  } else if (distanceLake === 0) {
    question = $gettext('How far from the lake is %{campsite}?');
    answer = $gettext('%{campsite} is located directly at the lake.');
    isAtWater = true;
  } else if (distanceRiver === 0) {
    question = $gettext('How far from the river is %{campsite}?');
    answer = $gettext('%{campsite} is located directly at the river.');
    isAtWater = true;
  } else {
    const vals = [
      { name: 'sandBeachValue', value: sandBeachValue },
      { name: 'distanceSea', value: distanceSea },
      { name: 'distanceLake', value: distanceLake },
      { name: 'distanceRiver', value: distanceRiver },
    ];
    vals.sort((a, b) => {
      if (a.value === b.value) {
        return 0;
      }
      if (a.value === null) {
        return -1;
      }
      if (b.value === null) {
        return 1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return -1;
    });
    vals.forEach((val) => {
      if (!isAtWater) {
        if (val.name === 'sandBeachValue') {
          if (sandBeachValue != null && sandBeachValue >= 0) {
            question = $gettext('How far from the beach is %{campsite}?');
            answer = $gettext('%{campsite} is %{sandBeachValue} %{sandBeachUnit} away from the beach.');
            isAtWater = true;
          }
        } else if (val.name === 'distanceSea') {
          if (distanceSea != null && distanceSea >= 0) {
            question = $gettext('How far from the sea is %{campsite}?');
            answer = $gettext('%{campsite} is %{distanceSea} km away from the sea.');
            isAtWater = true;
          }
        } else if (val.name === 'distanceLake') {
          if (distanceLake != null && distanceLake >= 0) {
            question = $gettext('How far from the lake is %{campsite}?');
            answer = $gettext('%{campsite} is %{distanceLake} km away from the lake.');
            isAtWater = true;
          }
        } else if (val.name === 'distanceRiver') {
          if (distanceRiver != null && distanceRiver >= 0) {
            question = $gettext('How far from the river is %{campsite}?');
            answer = $gettext('%{campsite} is %{distanceRiver} km away from the river.');
            isAtWater = true;
          }
        }
      }
    });
  }

  /*
    else if (sandBeachValue != null && sandBeachValue >= 0) {
      question = $gettext('How far from the beach is %{campsite}?');
      answer = $gettext('%{campsite} is %{sandBeachValue} %{sandBeachUnit} away from the beach.');
      isAtWater = true;
    } else if (distanceSea != null && distanceSea >= 0) {
      question = $gettext('How far from the sea is %{campsite}?');
      answer = $gettext('%{campsite} is %{distanceSea} km away from the sea.');
      isAtWater = true;
    } else if (distanceLake != null && distanceLake >= 0) {
      question = $gettext('How far from the lake is %{campsite}?');
      answer = $gettext('%{campsite} is %{distanceLake} km away from the lake.');
      isAtWater = true;
    } else if (distanceRiver != null && distanceRiver >= 0) {
      question = $gettext('How far from the river is %{campsite}?');
      answer = $gettext('%{campsite} is %{distanceRiver} km away from the river.');
      isAtWater = true;
    }
    */

  if (isAtWater && nudistBeachValue !== null) {
    if (nudistBeachValue === 0) {
      answer += ' ' + $gettext('There is also a nudist beach, which is located directly at the site.');
    } else if (nudistBeachValue > 0) {
      answer += ' ' + $gettext('There is also a nudist beach, which is %{nudistBeachValue} %{nudistBeachUnit} away.');
    }
  }

  if (!answer || !question) {
    return null;
  }

  answer += ' ' + `<a href="${cpdpLinkUrl.value}#location">${$gettext('Learn more about the location')}</a>`;

  return {
    question: interpolate(question, { campsite: props.campsite.meta.name }, true),
    answer: interpolate(
      answer,
      {
        distanceSea,
        distanceLake,
        distanceRiver,
        sandBeachValue,
        sandBeachUnit,
        nudistBeachValue,
        nudistBeachUnit,
        sendRequestLink: sendRequestLink.value,
        campsite: props.campsite.meta.name,
        federal_state: props.campsite.meta.federal_state.name,
      },
      true,
    ),
  };
}

function getAccessibilityQuestion() {
  const question = $gettext('Is %{campsite} barrier-free?');
  let answer = $gettext(
    'Unfortunately, the campsite %{campsite} has not yet provided any information '
    + 'about accessibility, but you can also ask for the info directly from the campsite. %{sendRequestLink}<br /><br /> '
    + '%{searchForOthersLink}',
  );
  const searchForOthersLink = getSearchForOthersLink($gettext('Search for other campsites in %{federal_state}'), []);
  const wheelchairRamps = getValueOrNull(props.campsite.general?.wheelchair_ramps);
  const mostWaysPaved = getValueOrNull(props.campsite.descriptive?.most_ways_paved);
  const accessibleWaterEntrance = getValueOrNull(props.campsite.equipment?.handicapped_accessible_water_entrance);
  const accessibleSanitary = getValueOrNull(props.campsite.sanitary?.sanitarian_handicapped);

  if (
    wheelchairRamps !== null
    || mostWaysPaved !== null
    || accessibleWaterEntrance !== null
    || accessibleSanitary !== null
  ) {
    answer = '';
    if (mostWaysPaved === true) {
      answer = $gettext('Most of the paths at campsite %{campsite} are paved.');
    } else if (mostWaysPaved === false) {
      answer = $gettext('Most of the paths at campsite %{campsite} are not paved.');
    }
    if (wheelchairRamps !== null) {
      answer += ' ';
    }
    if (wheelchairRamps === true) {
      answer += $gettext('Ramps are available for wheelchair users.');
    } else if (wheelchairRamps === false) {
      answer += $gettext('There are no ramps for wheelchair users.');
    }
    if (accessibleSanitary !== null) {
      answer += ' ';
    }
    if (accessibleSanitary === true) {
      answer += $gettext('The sanitary facilities are barrier-free.');
    } else if (accessibleSanitary === false) {
      answer += $gettext('The sanitary facilities are not barrier-free.');
    }
    if (accessibleWaterEntrance === true) {
      answer
        += ' ' + $gettext('Nothing stands in the way of swimming fun, because access to the water is barrier-free.');
    }
    answer += ' ' + `<a href="${cpdpLinkUrl.value}#properties">${$gettext('View all campsite features')}</a>`;
  }

  return {
    question: interpolate(question, { campsite: props.campsite.meta.name }, true),
    answer: interpolate(
      answer,
      {
        searchForOthersLink,
        sendRequestLink: sendRequestLink.value,
        campsite: props.campsite.meta.name,
        federal_state: props.campsite.meta.federal_state.name,
      },
      true,
    ),
  };
}

function getCateringQuestion() {
  const question = $gettext('What food options does %{campsite} offer?');
  const searchForOthersLink = getSearchForOthersLink(
    $gettext('Search for other campsites with restaurant in %{federal_state}'),
    ['restaurant'],
  );

  const { distance: restaurantDistance, unit: restaurantDistanceUnit } = getDistanceAndUnit(
    props.campsite.care?.restaurant,
  );
  const { distance: snackDistance, unit: snackDistanceUnit } = getDistanceAndUnit(props.campsite.care?.snack);
  const { distance: groceryDistance, unit: groceryDistanceUnit } = getDistanceAndUnit(props.campsite.care?.food_supply);
  const { distance: gasExchangeDistance, unit: gasExchangeDistanceUnit } = getDistanceAndUnit(
    props.campsite.care?.gas_bottle_exchange,
  );

  const restaurantBegin = getFormattedOpeningDate(props.campsite.meta?.opening_hours_restaurant_begin);
  const restaurantEnd = getFormattedOpeningDate(props.campsite.meta?.opening_hours_restaurant_end);
  const snackBegin = getFormattedOpeningDate(props.campsite.meta?.opening_hours_snack_begin);
  const snackEnd = getFormattedOpeningDate(props.campsite.meta?.opening_hours_snack_end);
  const groceryBegin = getFormattedOpeningDate(props.campsite.meta?.opening_hours_foodsupply_begin);
  const groceryEnd = getFormattedOpeningDate(props.campsite.meta?.opening_hours_foodsupply_end);

  const bread = props.campsite.descriptive?.raw_bread_at_campsite || 0;
  const cooking = getValueOrNull(props.campsite.equipment?.cooking_facilities);
  const grill = getValueOrNull(props.campsite.general?.charcoal_grill_allowed);
  const tablesAndBenches = getValueOrNull(props.campsite.equipment?.tables_and_benches);

  const cateringRating = getValueOrNull(props.campsite.rating_campsite?.rating_avg_catering);

  let answer = $gettext(
    'Unfortunately, the campsite %{campsite} has not yet provided any information about '
    + 'dining options, but you can request the info directly from the campsite. %{sendRequestLink}',
  );
  if (cateringRating !== null) {
    answer
      += '<br /><br />'
        + $gettext('Overall, guests rate the food and beverage at the site with %{cateringRating} out of 5.');
  }
  answer += '<br /><br />%{searchForOthersLink}';

  let hasFood = false;

  if (restaurantDistance !== null && restaurantDistance >= 0) {
    hasFood = true;
    if (restaurantDistance === 0) {
      answer = $gettext('Food is well taken care of, because there is a restaurant directly at the site.');
    } else {
      answer = $gettext(
        'Food is well taken care of, because there is a restaurant in %{restaurantDistance} %{restaurantDistanceUnit} distance.',
      );
    }
    if (restaurantBegin !== null && restaurantEnd !== null) {
      answer += ' ' + $gettext('It is open from %{restaurantBegin} to %{restaurantEnd}.');
    }
  }

  if (snackDistance !== null && snackDistance >= 0) {
    if (hasFood) {
      if (snackDistance === 0) {
        answer += ' ' + $gettext('Furthermore, there is also a snack bar directly at the site');
      } else {
        answer
          += ' '
            + $gettext('Furthermore, there is also a snack bar at a distance of %{snackDistance} %{snackDistanceUnit}');
      }
    } else {
      if (snackDistance === 0) {
        answer = $gettext('There is a snack bar directly at the site');
      } else {
        answer = $gettext('There is a snack bar %{snackDistance} %{snackDistanceUnit} away');
      }
    }
    if (snackBegin !== null && snackEnd !== null) {
      answer += ' ' + $gettext('(open from %{snackBegin} to %{snackEnd})');
    }
    answer += '.';
    hasFood = true;
  }

  if (hasFood) {
    if (bread > 1) {
      answer += '<br /><br />';
      if (bread === 2) {
        answer += $gettext(
          'With the bread service directly at the site (only in high season) you can start the day relaxed.',
        );
      } else {
        answer += $gettext('With the bread service directly at the site you can start the day relaxed.');
      }
    }
  }

  if (groceryDistance !== null && groceryDistance >= 0) {
    if (hasFood) {
      answer += '<br /><br />';
    }
    if (groceryDistance === 0) {
      answer += $gettext('For self-catering there is a grocery store directly at the site');
    } else {
      answer += $gettext(
        'For self-catering there is a grocery store at a distance of %{groceryDistance} %{groceryDistanceUnit}',
      );
    }
    if (groceryBegin !== null && groceryEnd !== null) {
      answer += ' ' + $gettext('(open from %{groceryBegin} to %{groceryEnd})');
    }

    if (cooking === true) {
      answer += ' ' + $gettext('as well as cooking facilities on the site');
    }

    answer += '.';
  } else if (hasFood && cooking === true) {
    answer += '<br /><br />' + $gettext('Cooking facilities on the site are also available.');
  }

  if (hasFood && grill === true) {
    answer += ' ' + $gettext('Real barbecue fans may even fire up the charcoal grill.');
  }

  if (hasFood && cateringRating !== null) {
    answer
      += '<br /><br />' + $gettext('Overall, guests rate the food and beverage at the site %{cateringRating} out of 5.');
  }

  if (hasFood && gasExchangeDistance !== null && gasExchangeDistance >= 0) {
    answer += '<br /><br />';
    if (gasExchangeDistance === 0) {
      answer += $gettext('By the way, gas bottles can be exchanged directly at the site.');
    } else {
      answer += $gettext(
        'By the way, gas bottles can be exchanged in %{gasExchangeDistance} %{gasExchangeDistanceUnit} distance.',
      );
    }
  }

  if (hasFood && tablesAndBenches === true) {
    answer += ' ' + $gettext('And those who want to borrow a table or benches can also do so on site.');
  }

  if (hasFood) {
    answer
      += '<br /><br />' + `<a href="${cpdpLinkUrl.value}#properties">${$gettext('View all options in detail')}</a>`;
  }

  return {
    question: interpolate(question, { campsite: props.campsite.meta.name }, true),
    answer: interpolate(
      answer,
      {
        restaurantDistance,
        restaurantDistanceUnit,
        snackDistance,
        snackDistanceUnit,
        groceryDistance,
        groceryDistanceUnit,
        gasExchangeDistance,
        gasExchangeDistanceUnit,
        restaurantBegin,
        restaurantEnd,
        snackBegin,
        snackEnd,
        groceryBegin,
        groceryEnd,
        cateringRating,
        searchForOthersLink,
        sendRequestLink: sendRequestLink.value,
        campsite: props.campsite.meta.name,
        federal_state: props.campsite.meta.federal_state.name,
      },
      true,
    ),
  };
}

function getRatingsQuestion() {
  const question = $gettext('How do guests and other campers rate %{campsite}?');
  let answer = $gettext(
    'Campsite %{campsite} has not been rated by other campers yet.<br /><br />%{rateCampsiteLink}<br /><br /> '
    + '%{searchForOthersLink}',
  );
  let searchForOthersLink = getSearchForOthersLink($gettext('Show top campsites in %{federal_state}'), []);
  const regions = props.campsite.regions || [];
  regions.forEach((region) => {
    searchForOthersLink
      += '<br />' + getSearchForOthersInRegionLink($gettext('Show top campsites in region %{region}'), region, []);
  });
  const ratingOverall = getValueOrNull(props.campsite.rating_campsite?.rating_avg_overall);
  const topForLocation = props.campsite.meta?.top_for_location || null;

  const ratingKeys = [
    'rating_avg_calmness',
    'rating_avg_catering',
    'rating_avg_cleanliness_overall',
    'rating_avg_cleanliness_sanitary',
    'rating_avg_condition_rentable_accomodations',
    'rating_avg_friendliness',
    'rating_avg_infrastructure',
    'rating_avg_leisure',
    'rating_avg_location',
    'rating_avg_price_quality_ratio',
    'rating_avg_sanitary',
  ];

  let topRatedFeatures = '';
  let topRatedGuestFeatures = '';
  let idealFor = '';

  if (ratingOverall !== null) {
    answer = $gettext('Campers rate %{campsite} %{ratingOverall} out of 5.');
    if (topForLocation !== null) {
      answer += ' ';
      let sentence;
      if (topForLocation.type === 'region') {
        sentence = $gettext('This makes %{campsite} one of the top rated campsites in region %{location}.');
      } else if (topForLocation.type === 'federal_state') {
        sentence = $gettext('This makes %{campsite} one of the top rated campsites in federal state %{location}.');
      } else {
        sentence = $gettext('This makes %{campsite} one of the top rated campsites in %{location}.');
      }
      answer += interpolate(sentence, { campsite: props.campsite.meta.name, location: topForLocation.name }, true);
    }

    const relevantRatings = [];
    ratingKeys.forEach((ratingKey) => {
      const ratingValue = getValueOrNull(props.campsite.rating_campsite[ratingKey]);
      if (ratingValue !== null && ratingValue >= 4) {
        relevantRatings.push({ name: getRatingTranslation(ratingKey), value: ratingValue });
      }
    });
    relevantRatings.sort((a, b) => {
      if (a.value === b.value) {
        return 0;
      }
      if (a.value === null) {
        return 1;
      }
      if (b.value === null) {
        return -1;
      }
      if (a.value > b.value) {
        return -1;
      }
      return 1;
    });
    if (relevantRatings.length > 0) {
      answer
        += '<br /> <br />' + $gettext('Guests rate the following properties particularly well: %{topRatedFeatures}');
      topRatedFeatures = relevantRatings
        .slice(0, 3)
        .map(r => r.name)
        .join(', ');
    }

    const guestKeys = [
      'rating_avg_camper_with_tent_friendly',
      'rating_avg_couple_friendly',
      'rating_avg_guests_with_dogs_friendly',
      'rating_avg_kid_friendly',
      'rating_avg_kid_friendly18',
      'rating_avg_kid_friendly6',
      'rating_avg_senior_friendly',
      'rating_avg_youth_friendly',
    ];

    const and = $gettext('and');

    const relevantGuestRatings = [];
    guestKeys.forEach((ratingKey) => {
      const ratingValue = getValueOrNull(props.campsite.rating_guest[ratingKey]);
      if (ratingValue !== null && ratingValue >= 4) {
        relevantGuestRatings.push({
          name: $gettext(translationFixtures[ratingKey]),
          value: ratingValue,
        });
      }
    });
    relevantGuestRatings.sort((a, b) => {
      if (a.value === b.value) {
        return 0;
      }
      if (a.value === null) {
        return 1;
      }
      if (b.value === null) {
        return -1;
      }
      if (a.value > b.value) {
        return -1;
      }
      return 1;
    });

    if (relevantGuestRatings.length > 0) {
      answer
        += '<br /> <br />'
          + $gettext('According to its guests %{campsite} is particularly suitable for: %{topRatedGuestFeatures}');
      topRatedGuestFeatures = relevantGuestRatings
        .slice(0, 2)
        .map(r => r.name)
        .join(` ${and} `);
    }

    const idealActivities = props.campsite.ideal_activities || [];
    if (idealActivities.length > 0) {
      answer += '<br /> <br />' + $gettext('According to its guests %{campsite} is ideal for: %{idealFor}');
      idealFor = idealActivities
        .slice(0, 2)
        .map(r => $gettext(r.name))
        .join(` ${and} `);
    }

    answer += '<br /><br />' + `<a href="${cpdpLinkUrl.value}#rating">${$gettext('Read all camper reviews')}</a>`;
  }

  return {
    question: interpolate(question, { campsite: props.campsite.meta.name }, true),
    answer: interpolate(
      answer,
      {
        ratingOverall,
        searchForOthersLink,
        topRatedFeatures,
        topRatedGuestFeatures,
        idealFor,
        rateCampsiteLink: rateCampsiteLink.value,
        sendRequestLink: sendRequestLink.value,
        campsite: props.campsite.meta.name,
        federal_state: props.campsite.meta.federal_state.name,
      },
      true,
    ),
  };
}

function getFamilyFriendlyQuestion() {
  const question = $gettext('Is %{campsite} family friendly?');
  let answer = $gettext(
    'Unfortunately, the campsite %{campsite} has not yet provided any information about recreational '
    + 'opportunities for children, but you can request the info directly from the campsite. %{sendRequestLink}<br /><br /> ',
  );

  const searchForOthersLink = getSearchForOthersLink(
    $gettext('Search for other family-friendly campsites in %{federal_state}'),
    [{ name: 'rating_avg_kid_friendly6__gte', value: 4 }],
  );

  const kidFriendly6 = getValueOrNull(props.campsite.rating_guest?.rating_avg_kid_friendly6);
  const kidFriendly12 = getValueOrNull(props.campsite.rating_guest?.rating_avg_kid_friendly);
  const kidFriendly18 = getValueOrNull(props.campsite.rating_guest?.rating_avg_kid_friendly18);

  const isKidFriendly6 = kidFriendly6 !== null && kidFriendly6 >= 4;
  const isKidFriendly12 = kidFriendly12 !== null && kidFriendly12 >= 4;
  const isKidFriendly18 = kidFriendly18 !== null && kidFriendly18 >= 4;

  const hasGoodRatings = isKidFriendly6 || isKidFriendly12 || isKidFriendly18;

  const { distance: playgroundDistance } = getDistanceAndUnit(props.campsite.leisure?.childrens_playground);
  const { distance: ridingDistance } = getDistanceAndUnit(props.campsite.leisure?.horseback_riding);
  const { distance: indoorPoolDistance, unit: indoorPoolDistanceUnit } = getDistanceAndUnit(
    props.campsite.leisure?.swimming_pool_indoor,
  );
  const { distance: outdoorPoolDistance } = getDistanceAndUnit(props.campsite.leisure?.swimming_pool_outdoor);
  const { distance: waterSlideDistance } = getDistanceAndUnit(props.campsite.leisure?.water_slide);

  const zoo = getValueOrNull(props.campsite.leisure?.petting_zoo);
  const kidsAnimation = getValueOrNull(props.campsite.leisure?.kids_animation);
  const miniGolf = getValueOrNull(props.campsite.leisure?.mini_golf);
  const tableTennis = getValueOrNull(props.campsite.leisure?.table_tennis);
  const indoorGaming = getValueOrNull(props.campsite.leisure?.indoor_gaming_possibilities);

  const hasPlayground = playgroundDistance !== null && playgroundDistance >= 0;
  const hasRiding = ridingDistance !== null && ridingDistance >= 0;
  const hasIndoorPool = indoorPoolDistance !== null && indoorPoolDistance >= 0;
  const hasOutdoorPool = outdoorPoolDistance !== null && outdoorPoolDistance >= 0;
  const hasWaterSlide = waterSlideDistance !== null && waterSlideDistance >= 0;
  const hasZoo = zoo === true;
  const hasKidsAnimation = kidsAnimation === true;
  const hasMiniGolf = miniGolf === true;
  const hasTableTennis = tableTennis === true;
  const hasIndoorGaming = indoorGaming === true;

  const hasKidsLeisure
    = hasPlayground
      || hasRiding
      || hasIndoorPool
      || hasOutdoorPool
      || hasWaterSlide
      || hasZoo
      || hasKidsAnimation
      || hasMiniGolf
      || hasTableTennis
      || hasIndoorGaming;

  let years = '';
  let outdoorActivities = '';
  const and = $gettext('and');

  if (hasGoodRatings) {
    const yearList = [];
    if (isKidFriendly6) {
      yearList.push(6);
    }
    if (isKidFriendly12) {
      yearList.push(12);
    }
    if (isKidFriendly18) {
      yearList.push(18);
    }

    if (yearList.length === 1) {
      years = yearList[0];
    } else if (yearList.length === 2) {
      years = yearList.join(` ${and} `);
    } else {
      const lastYear = yearList.pop();
      years = yearList.join(', ') + ` ${and} ${lastYear}`;
    }
    answer = $gettext(
      'Guests rate %{campsite} as particularly suitable for families with children under %{years} years.',
    );
  }

  let indoorPoolDistanceText = '';

  if (hasKidsLeisure) {
    const activityList = [];
    if (hasPlayground) {
      activityList.push($gettext(translationFixtures.childrens_playground));
    }
    if (hasRiding) {
      activityList.push($gettext(translationFixtures.horseback_riding));
    }
    if (hasOutdoorPool) {
      activityList.push($gettext(translationFixtures.swimming_pool_outdoor));
    }
    if (hasWaterSlide) {
      activityList.push($gettext(translationFixtures.water_slide));
    }
    if (hasZoo) {
      activityList.push($gettext(translationFixtures.petting_zoo));
    }
    if (hasKidsAnimation) {
      activityList.push($gettext(translationFixtures.kids_animation));
    }
    if (hasMiniGolf) {
      activityList.push($gettext(translationFixtures.mini_golf));
    }
    if (hasTableTennis) {
      activityList.push($gettext(translationFixtures.table_tennis));
    }
    outdoorActivities = activityList.join(', ');

    let leisureAnswer = '';
    if (outdoorActivities) {
      leisureAnswer = $gettext(
        'For leisure activities, the campsite %{campsite} offers many possibilities, such as %{outdoorActivities} and much more.',
      );
    }
    if (hasIndoorPool || hasIndoorGaming) {
      let indoorAnswer;
      if (indoorPoolDistance === 0) {
        indoorPoolDistanceText = $pgettext('As in "facility is on site"', 'on site');
      } else {
        indoorPoolDistanceText = interpolate(
          $pgettext('E.g.: "An indoor pool is »2 km« away"', '%{indoorPoolDistance} %{indoorPoolDistanceUnit} away'),
          { indoorPoolDistance, indoorPoolDistanceUnit },
          true,
        );
      }
      if (hasIndoorPool && hasIndoorGaming) {
        indoorAnswer = $gettext(
          'In case of bad weather you can use the indoor pool (%{indoorPoolDistance}) and the indoor play facilities.',
        );
      } else if (hasIndoorPool) {
        indoorAnswer = $gettext('In case of bad weather you can use the indoor pool (%{indoorPoolDistance}).');
      } else {
        indoorAnswer = $gettext('In case of bad weather you can use the indoor play facilities.');
      }
      if (outdoorActivities) {
        leisureAnswer += ' ' + indoorAnswer;
      } else {
        leisureAnswer = indoorAnswer;
      }
    }
    if (hasGoodRatings) {
      answer += ' ' + leisureAnswer;
    } else {
      answer = leisureAnswer;
    }
  }

  if (!hasGoodRatings && !hasKidsLeisure) {
    if (kidFriendly6 !== null || kidFriendly12 !== null || kidFriendly18 !== null) {
      answer += $gettext('Guests rate %{campsite} as especially suitable for');
      answer += ' <ul>';
      if (kidFriendly6 !== null) {
        answer += '<li>' + $gettext('"Families with children under 6" with %{kidFriendly6} out of 5 points') + '</li>';
      }
      if (kidFriendly12 !== null) {
        answer
          += '<li>' + $gettext('"Families with children under 12" with %{kidFriendly12} out of 5 points') + '</li>';
      }
      if (kidFriendly18 !== null) {
        answer
          += '<li>' + $gettext('"Families with children under 18" with %{kidFriendly18} out of 5 points') + '</li>';
      }
      answer += '</ul><br /><br />';
    }
    answer += '%{searchForOthersLink}';
  } else {
    answer += '<br /><br />';
    if (hasKidsLeisure) {
      answer += `<a href="${cpdpLinkUrl.value}#properties">${$gettext('View all recreational opportunities')}</a>`;
    } else {
      answer += `<a href="${cpdpLinkUrl.value}#rating">${$gettext('Read all camper reviews')}</a>`;
    }
  }

  return {
    question: interpolate(question, { campsite: props.campsite.meta.name }, true),
    answer: interpolate(
      answer,
      {
        outdoorActivities,
        years,
        kidFriendly6,
        kidFriendly12,
        kidFriendly18,
        searchForOthersLink,
        indoorPoolDistance: indoorPoolDistanceText,
        sendRequestLink: sendRequestLink.value,
        campsite: props.campsite.meta.name,
        federal_state: props.campsite.meta.federal_state.name,
      },
      true,
    ),
  };
}

function getPitchesQuestion() {
  const question = $gettext('How many pitches does %{campsite} have?');

  const countSpace = props.campsite?.count?.count_space_cars;
  const countSpaceTourism = props.campsite?.count?.count_space_tourism;
  const countSpaceTourismSeparated = props.campsite?.count?.count_space_tourism_separated;
  const countPermanent = countSpace && countSpaceTourism ? countSpace - countSpaceTourism : null;
  const countMotorhomePitchesOutsideGate = props.campsite?.count?.count_motorhome_pitches_outside_gate;
  const caravanPitchesOutsideGate = props.campsite?.equipment?.caravan_pitches_outside_gate;

  // no info for tourist spaces and no info for countSpaceTourismSeparated
  if (
    (countSpaceTourism === null || countSpaceTourism === undefined)
    && (countSpaceTourismSeparated === null || countSpaceTourismSeparated === undefined)
  ) {
    const text = $gettext(
      'Unfortunately, the campsite %{campsite} has not yet provided any information about tourist pitches. %{sendRequestLink}',
    );

    let answer = interpolate(
      text,
      { campsite: props.campsite.meta.name, sendRequestLink: sendRequestLink.value },
      true,
    );

    answer
      += '<br /><br />' + `${getSearchForOthersLink($gettext('Search for other campsites in %{federal_state}'), [])}`;

    return {
      question: interpolate(question, { campsite: props.campsite.meta.name }, true),
      answer,
    };
  }

  const showAllInfos = `<br /><br /><a href="${cpdpLinkUrl.value}#top">${$gettext(
    'View all campsite information now',
  )}</a>`;

  // tourist spaces and separated spaces === 0
  if (countSpaceTourism === 0 && countSpaceTourismSeparated === 0) {
    const text = $gettext('The campsite %{campsite} unfortunately has no tourist pitches.');
    return {
      question: interpolate(question, { campsite: props.campsite.meta.name }, true),
      answer: interpolate(text, { campsite: props.campsite.meta.name }, true) + ` ${showAllInfos}`,
    };
  }

  if (countSpaceTourism > 0 || countSpaceTourismSeparated > 0) {
    const text = $gettext(
      '%{campsite} has %{countSpaceTourism} pitches for tourists and %{countPermanent} pitches for permanent campers.',
    );
    let answer = interpolate(
      text,
      {
        campsite: props.campsite.meta.name,
        countSpaceTourism,
        countPermanent,
      },
      true,
    );

    if (countMotorhomePitchesOutsideGate > 0 && caravanPitchesOutsideGate > 0) {
      answer
        = answer + ` ${$gettext('In front of the barrier there are parking spaces for motorhomes and for caravans.')}`;
    } else if (
      countMotorhomePitchesOutsideGate
      && countMotorhomePitchesOutsideGate > 0
      && (!caravanPitchesOutsideGate || caravanPitchesOutsideGate === 0)
    ) {
      answer = answer + ` ${$gettext('In front of the barrier there are parking spaces for motorhomes.')}`;
    } else if (
      (!countMotorhomePitchesOutsideGate || countMotorhomePitchesOutsideGate === 0)
      && caravanPitchesOutsideGate
      && caravanPitchesOutsideGate > 0
    ) {
      answer = answer + ` ${$gettext('In front of the barrier there are parking spaces for caravans.')}`;
    }

    return {
      question: interpolate(question, { campsite: props.campsite.meta.name }, true),
      answer: answer + ` ${showAllInfos}`,
    };
  }

  return false;
}

function getSizeQuestion() {
  const translatedQuestion = $gettext('How big is %{campsite}?');
  const question = interpolate(translatedQuestion, { campsite: props.campsite.meta.name }, true);
  const size = props.campsite?.meta?.size && new Intl.NumberFormat(locale.value).format(props.campsite.meta.size);
  const groundDescription = props.campsite.descriptive?.ground_composition_predominantly?.message;

  if (size === null || size === undefined || size === 0) {
    const text = $gettext(
      'Unfortunately, the campsite %{campsite} has not yet provided any information about the size, but you can also request the info directly from the site. %{sendRequestLink}',
    );
    const answer = interpolate(
      text,
      { campsite: props.campsite.meta.name, sendRequestLink: sendRequestLink.value },
      true,
    );
    return {
      question: interpolate(question, { campsite: props.campsite.meta.name }, true),
      answer:
        answer
        + '<br /><br />'
        + `${getSearchForOthersLink($gettext('Search for other campsites in %{federal_state}'), [])}`,
    };
  }
  const text = $gettext('%{campsite} has a size of %{size} hectares.');
  let answer = interpolate(text, { campsite: props.campsite.meta.name, size }, true);

  const showAllInfos = `<br /><br /><a href="${cpdpLinkUrl.value}#top">${$gettext(
    'View all campsite information now',
  )}</a>`;

  if (groundDescription) {
    const text = $gettext('Ground condition is: %{groundDescription}');
    const ground = interpolate(text, { groundDescription }, true);
    answer = `${answer} ${ground}`;
  }

  answer = `${answer} ${showAllInfos}`;

  return {
    question,
    answer,
  };
}

function getCostQuestion() {
  const question = interpolate(
    $gettext('How much does it cost to stay at %{campsite}?'),
    { campsite: props.campsite.meta.name },
    true,
  );

  const mainSeasonPrice = props.campsite.price?.price1;
  const offSeasonPrice = props.campsite.price?.price_off_season;

  let formattedMainSeasonPrice = '';
  let formattedOffSeasonPrice = '';

  if (mainSeasonPrice) {
    formattedMainSeasonPrice = new Intl.NumberFormat(locale.value, { style: 'currency', currency: 'EUR' }).format(
      mainSeasonPrice,
    );
  }

  if (offSeasonPrice) {
    formattedOffSeasonPrice = new Intl.NumberFormat(locale.value, { style: 'currency', currency: 'EUR' }).format(
      offSeasonPrice,
    );
  }

  const showAllInfos = `<br /><br /><a href="${cpdpLinkUrl.value}#top">${$gettext(
    'View all campsite information now',
  )}</a>`;

  let answer;

  if (mainSeasonPrice || offSeasonPrice) {
    const hasBothPrices = mainSeasonPrice && offSeasonPrice;
    const textBothPrices = $gettext(
      'An overnight stay at %{campsite} costs %{formattedOffSeasonPrice} - %{formattedMainSeasonPrice}, but prices vary depending on the chosen period, guests, etc.',
    );

    let answerASBothPrices = '';
    if (hasBothPrices) {
      answerASBothPrices = interpolate(
        textBothPrices,
        {
          campsite: props.campsite.meta.name,
          formattedMainSeasonPrice,
          formattedOffSeasonPrice,
        },
        true,
      );
    }

    const textOnePrice = $gettext(
      'An overnight stay at %{campsite} costs from %{price}, but prices vary depending on the chosen period, guests, etc.',
    );
    const answerASOnePrice = interpolate(
      textOnePrice,
      {
        campsite: props.campsite.meta.name,
        price: formattedMainSeasonPrice || formattedOffSeasonPrice,
      },
      true,
    );

    if (props.hasAvailability) {
      answer = hasBothPrices && mainSeasonPrice !== offSeasonPrice ? answerASBothPrices : answerASOnePrice;
      answer
        = answer
          + ' '
          + $gettext('At camping.info you can enter your travel dates to see current prices and availability.')
          + `<br /><br /><a href="${cpdpLinkUrl.value}#availabilities">${$gettext(
            'Enter your travel dates now and view current prices',
          )}</a>`;
    } else if (props.campsite.meta?.inquiries_allowed) {
      const text = $gettext(
        'At camping.info you can send a no-obligation request to %{campsite} to find out current prices and availability. %{sendRequestLink}',
      );
      answer = hasBothPrices && mainSeasonPrice !== offSeasonPrice ? answerASBothPrices : answerASOnePrice;
      answer
        = answer
          + ' '
          + interpolate(
            text,
            {
              campsite: props.campsite.meta.name,
              sendRequestLink: sendRequestLink.value,
            },
            true,
          );
    } else {
      answer = hasBothPrices && mainSeasonPrice !== offSeasonPrice ? answerASBothPrices : answerASOnePrice;
      answer = answer + ` ${showAllInfos}`;
    }

    return {
      question,
      answer,
    };
  } else {
    const text = $gettext(
      'Unfortunately, the campsite %{campsite} has not yet provided any information about prices, but you can also request the info directly from the site. %{sendRequestLink}',
    );
    const answer = interpolate(
      text,
      { campsite: props.campsite.meta.name, sendRequestLink: sendRequestLink.value },
      true,
    );
    return {
      question,
      answer:
        answer
        + '<br /><br />'
        + `${getSearchForOthersLink($gettext('Search for other campsites in %{federal_state}'), [])}`,
    };
  }
}

function getCCAQuestion() {
  if (!props.campsite.price?.campingcard?.rate) {
    return false;
  }
  const qtrans = $gettext('Does %{campsite} accept the CampingCard ACSI?');
  const question = interpolate(qtrans, { campsite: props.campsite.meta.name }, true);

  const part1 = $gettext('%{campsite} accepts CampingCard ACSI in the following periods:');
  let answer = interpolate(part1, { campsite: props.campsite.meta.name }, true);

  const period = props.campsite.price?.campingcard?.acceptance_periods.reduce((accumulator, period, _index) => {
    return `${accumulator}<li>${getLocalizedPeriod(period)}</li>`;
  }, '');
  answer += `<br /></br /><ul>${period}</ul>`;
  answer = `${answer} ${i18nCampingCardInfoText2.value}`;

  if (props.hasAvailability) {
    answer += ` <a href="${cpdpLinkUrl.value}#availabilities">${$gettext('Check availabilities now')}</a>`;
  } else {
    answer += ` <a href="${cpdpLinkUrl.value}#about">${$gettext('Show campsite details')}</a>`;
  }
  answer += `<br /><br /> ${i18nCampingCardInfoAdditionalInfo.value}`;

  return {
    question,
    answer,
  };
}
</script>

<template>
  <section data-nosnippet>
    <h2
      key="section-headline-faq"
      class="px-6 text-center text-black"
    >
      {{ $gettext('Frequently asked questions') }}
    </h2>

    <div class="container mt-12">
      <template
        v-for="(faq, index) in faqs"
        :key="`faq-${index}`"
      >
        <details class="details">
          <summary class="accordion__header flex flex-row gap-[30px] px-[15px]">
            <h5 class="m-0 py-6 text-black max-md:text-base max-md:font-medium max-md:leading-6">
              {{ faq.question }}
            </h5>
            <CiAwesomeIcon
              :icon="faChevronDown"
              class="accordion__icon ml-auto self-center fill-primary transition-transform duration-[250ms] ease-in"
              ratio="0.75"
            />
          </summary>
          <div class="container pb-2">
            <div class="row">
              <div
                class="col-12 col-lg-10 text-black"
                @click="onAnswerClicked"
              >
                <div v-html="faq.answer" />
              </div>
            </div>
          </div>
        </details>
      </template>
    </div>
  </section>
</template>

<style></style>
